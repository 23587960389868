import "./BillingSummary.css";
// import second from '../../img/bookappointmentcover.jpg'
import LeafImage from "../../img/receptionleaf.png";
import LeafImage2 from "../../img/billingsummaryleaf.png";
import BSServicesBox from "./BSServicesBox/BSServicesBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addNewBooking, getVatRateById, validateCoupon } from "../../api/apicalls";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";
import InvoicePayment from "../Invoice/InvoicePayment/InvoicePayment";
import moment from "moment";
import MapView from "../MapView";

const BillingSummary = () => {
  const navigate = useNavigate();
  const branch_name = localStorage.getItem("branchName");
  const branch_id = localStorage.getItem("branchId");
  const { state } = useLocation();
  const [servicesData, setServicesData] = useState(state ? state.servicesData : []);
  const [price, setPrice] = useState(0);
  const [netPrice, setNetPrice] = useState(0);
  let [discountedNetPrice, setDiscountedNetPrice] = useState("");
  const [vat, setVat] = useState(0);
  // let vatPercentage = 0.15;
  const [coupon, setCoupon] = useState("");
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  let [discount, setDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [paymentpopup, setpaymentpopup] = useState(false);
  let [vatPercentage, setVatPercentage] = useState(0);
  const [postCallProcessing, setPostCallProcessing] = useState(false);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [startingMinutes, setStartingMinutes] = useState(15);
  const [endingMinutes, setEndingMinutes] = useState(15);
  const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];

  useEffect(() => {
    fetchVatRate();
  }, [servicesData]);

  console.log("servicesData", servicesData);

  const setPriceValues = () => {
    let newPrice = 0;
    let newNetPrice = 0;
    for (let index = 0; index < servicesData.length; index++) {
      const element = servicesData[index];
      newPrice = newPrice + element.price;
    }
    setPrice(newPrice);
    newNetPrice = newPrice * vatPercentage;
    setVat(newNetPrice);
    let totalPrice = newNetPrice + newPrice;
    setNetPrice(totalPrice);
  };

  const validateCouponAPI = () => {
    if (!isNullOrEmpty(coupon)) {
      let date = moment(new Date()).format("YYYY-MM-DDTHH:mm");
      setIsLoading(true);
      validateCoupon(branch_id, coupon, date)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.data.validateCoupon.error_code == 0) {
            setDiscountedNetPrice((discountedNetPrice = ""));
            console.log("result", data.data.validateCoupon.result);
            if (data.data.validateCoupon.result.type == 0) {
              // setNetPrice(netPrice - data.data.validateCoupon.result.amount);
              setDiscountedNetPrice(netPrice - data.data.validateCoupon.result.amount);
              setDiscount(data.data.validateCoupon.result.amount);
            } else {
              setDiscount((discount = price * (data.data.validateCoupon.result.amount / 100)));
              // setNetPrice(price + vat - discount);
              setDiscountedNetPrice(price + vat - discount);
            }
          } else setCouponErrorMessage(data.data.validateCoupon.message);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err", err);
        });
    } else setCouponErrorMessage("Please enter Coupon");
  };

  const addMinutes = (time, minutes) => {
    var date = new Date(new Date("01/01/2015 " + time).getTime() + minutes * 60000);
    var tempTime =
      (date.getHours().toString().length == 1 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes());
    return tempTime;
  };

  const postBooking = (paymentObject) => {
    if (cartProducts.length > 0) {
      let bookingArr = [];
      let vatPercentage = 0.15;
      for (let index = 0; index < servicesData.length; index++) {
        const element = servicesData[index];
        let startDate = element.start_datetime.split("T")[1];
        let endDate = element.end_datetime.split("T")[1];
        let date = element.start_datetime.split("T")[0];
        let cartObject = {
          id: 0,
          booking_id: 0,
          employee_user_id: parseInt(element.employee_user_id),
          service_id: parseInt(element.service_id),
          customer_user_id: parseInt(element.customer_user_id),
          price: element.price,
          is_executive: 0,
          start_datetime:
            date + "T" + addMinutes(startDate.substring(0, 6), startingMinutes) + ":00+00:00",
          end_datetime:
            date + "T" + addMinutes(endDate.substring(0, 6), endingMinutes) + ":00+00:00",
          status_id: 3,
        };
        bookingArr.push(cartObject);
      }

      let requestObj = {
        bookingModel: {
          id: 0,
          user_id: parseInt(servicesData[0].customer_user_id),
          status_id: 3,
          price: price,
          vat: vat,
          discount: discount,
          discount_code: coupon,
          net_price: discountedNetPrice ? discountedNetPrice : netPrice,
          payment_type_id:
            paymentObject && paymentObject.payment_type_id ? paymentObject.payment_type_id : 10,
          payment_status_id:
            paymentObject && paymentObject.payment_status_id ? paymentObject.payment_status_id : 12,
          booking_type_id: 7,
          lang: 1,
          branch_id: parseInt(branch_id),
          booking_details: bookingArr,
          latitude: lat.toString() || "0",
          longitude: lng.toString() || "0",
          address: address,
        },
      };

      console.log("bookingArr", bookingArr);
      setIsBookingLoading(true);
      addNewBooking(requestObj)
        .then(({ data }) => {
          setIsBookingLoading(false);
          if (data.data.postBooking.error_code == 0) navigate("/receptionist_dashboard");
          localStorage.removeItem("cartProducts");
          localStorage.removeItem("customer");
        })
        .catch((err) => {
          setIsBookingLoading(false);
          console.log("err", err);
        });
    } else navigate("/");
  };

  const onPayClick = (paymentObject) => {
    postBooking(paymentObject);
  };

  const fetchVatRate = () => {
    getVatRateById(1)
      .then(({ data }) => {
        console.log("data", data);
        if (data.error_code == 0) {
          setVatPercentage((vatPercentage = data.result.vat));
          setStartingMinutes(data.result.booking_start_time_adjustment || 15);
          setEndingMinutes(data.result.booking_end_time_adjustment || 15);
        }
        setPriceValues();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const calculateDiscountPercentage = () => {
    var result = (parseFloat(coupon) / 100) * netPrice;
    var price = netPrice - result;
    setDiscountedNetPrice(price);
  };

  const onPickLocation = (lat, lng) => {
    setLat(lat);
    setLng(lng);
  };

  return (
    <>
      <InvoicePayment
        paymentpopup={paymentpopup}
        setpaymentpopup={setpaymentpopup}
        netAmount={discountedNetPrice ? discountedNetPrice : netPrice}
        bookingId={0}
        isOnBillingSummary={true}
        onPayClick={onPayClick}
        isBookingLoading={isBookingLoading}
      />
      <div className="billing_summary_cover_image">
        {/* <img src={ScheduleImage} alt="" /> */}
        <p className="reception_heading">Book appointment</p>
      </div>

      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        {/* <div className="billing_summary_leaf2">
          <img src={LeafImage} alt="Leaf" />
        </div> */}

        <div className="billing_summary_main_container_wrapper">
          <div className="billing_summary_main_container">
            <div className="billing_summary_heading">Billing Summary</div>

            <div className="billing_summary_content_main_container">
              <div className="billing_summary_details_container">
                <div className="billing_summary_details1">
                  <div className="billing_summary_details_headings">
                    <div className="billing_summary_details_headings_style">Branch</div>

                    <div className="billing_summary_details_headings_style">Price</div>

                    <div className="billing_summary_details_headings_style">Discount</div>
                    <div className="billing_summary_details_headings_style">VAT</div>
                    <div className="billing_summary_details_headings_style">Total</div>
                  </div>

                  <div className="billing_summary_details_content">
                    <div className="billing_summary_details_content_style">{branch_name}</div>

                    <div className="billing_summary_details_content_style">{price} SAR</div>

                    <div className="billing_summary_details_content_style">{discount} SAR</div>
                    <div className="billing_summary_details_content_style">{vat} SAR</div>
                    {/* <div className="billing_summary_details_content_style"> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="billing_summary_details_content_style">
                        {discountedNetPrice ? parseInt(discountedNetPrice) : ""}
                      </div>
                      <div
                        className={
                          discountedNetPrice
                            ? "billing_summary_details_content_style__discount"
                            : "billing_summary_details_content_style"
                        }
                      >
                        {parseInt(netPrice)} SAR
                      </div>
                    </div>
                    {/* {netPrice} */}
                    {/* </div> */}
                  </div>

                  {/* <div className="billing_summary_leaf">
                    <img src={LeafImage2} alt="Leaf" />
                  </div> */}
                </div>
              </div>

              <div className="billing_summary_services_container">
                {servicesData.map((data) => (
                  <BSServicesBox data={data} />
                ))}
              </div>

              <div className="billing_summary_coupon_container">
                <div className="billing_summary_coupon_heading">Enter Coupon</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="text"
                    name="coupon"
                    id="coupon"
                    className="billing_summary_coupon_input"
                    placeholder="Enter here"
                    onChange={(e) => {
                      setCoupon(e.currentTarget.value);
                      setCouponErrorMessage("");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        validateCouponAPI();
                        if (!isNullOrEmpty(discountedNetPrice))
                          setCouponErrorMessage("You have availed the discount already");
                        else validateCouponAPI();
                      }
                    }}
                  />
                  <div style={{ fontSize: "12px", color: "red", marginLeft: "1em" }}>
                    {couponErrorMessage}
                  </div>
                </div>
                <button
                  className="inv_coupon_btn"
                  onClick={() => {
                    if (!isNullOrEmpty(discountedNetPrice))
                      setCouponErrorMessage("You have availed the discount already");
                    else validateCouponAPI();
                  }}
                  style={{ width: "18%", padding: "0.5em 0" }}
                >
                  {isLoading ? "----" : "Apply"}
                </button>
              </div>

              {/* <div className="billing_summary_cancel_container">
                <div className="billing_summary_cancel_heading">
                  Cancellation Policy
                </div>

                <div className="billing_summary_cancel_content">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book. It has
                  survived not only five centuries, but also the leap into
                  electronic typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with desktop
                  publishing software like Aldus PageMaker including versions of
                  Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer.
                </div>
              </div> */}
              {/* <button
                className="inv_coupon_btn"
                onClick={() => {
                  if (!isNullOrEmpty(discountedNetPrice))
                    setCouponErrorMessage(
                      "You have availed the discount already"
                    );
                  else calculateDiscountPercentage();
                }}
                style={{ width: "18%", padding: "0.5em 0" }}
              >
                {isLoading ? "----" : "Apply"}
              </button> */}
            </div>
            {localStorage.getItem("type") == 2 ||
            localStorage.getItem("type") == 3 ||
            localStorage.getItem("type") == 4 ? (
              <>
                <MapView onPickLocation={onPickLocation} />
                <div className="billing_summary_coupon_container">
                  <div className="billing_summary_coupon_heading">Enter Address</div>
                  <input
                    type="text"
                    className="billing_summary_coupon_input"
                    placeholder="Enter Address"
                    style={{ width: "60%" }}
                    onChange={(e) => setAddress(e.currentTarget.value)}
                    value={address}
                  />
                </div>
              </>
            ) : null}

            <div className="billing_summary_cancel_container">
              <div className="billing_summary_cancel_heading">Cancellation Policy</div>

              <div className="billing_summary_cancel_content">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions of Lorem
                Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                an unknown printer.
              </div>
            </div>

            <div className="billing_summary_buttons_container">
              {/* <button
                  className="billing_summary_paynow_btn"
                  onClick={() => setpaymentpopup(true)}
                >
                  Pay Now
                </button> */}
              <button
                className="billing_summary_payatstore_btn"
                onClick={() => {
                  if (
                    localStorage.getItem("type") == "3" ||
                    localStorage.getItem("type") == "4" ||
                    localStorage.getItem("type") == "2"
                  ) {
                    if (isNullOrEmpty(lat) || isNullOrEmpty(lng)) alert("Select location from Map");
                    else postBooking();
                  } else postBooking();
                }}
                disabled={isBookingLoading}
              >
                {isBookingLoading ? "Processing..." : "Pay at Store"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingSummary;

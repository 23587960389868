// import { useState } from 'react';
import "./ModifyBookingCard.css";
import moment from "moment/moment";
import { getAllAvailableEmployees, getAllAvailableServices } from "../../../api/apicalls";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";

const ModifyBookingCard = ({ data, index, handleValuesChange, isServiceUpdated }) => {
  const opening_time = localStorage.getItem("openingTime");
  const closing_time = localStorage.getItem("closingTime");
  const [employees, setEmployees] = useState(null);
  const [services, setServices] = useState([]);
  let [openingTime, setOpeningTime] = useState("");
  let [closingTime, setClosingTime] = useState("");
  let [updatedSlots, setUpdatedSlots] = useState("");
  let [slotData, setSlotData] = useState("");
  let date = new Date();
  let today = new Date().toISOString().slice(0, 10);
  const branch_id = localStorage.getItem("branchId");

  // const interval_time = localStorage.getItem("slotInterval");
  const interval_time = data.intervalTime;
  const [isDateChanged, setIsDateChanged] = useState(false);
  let [selectedDate, setSelectedDate] = useState(moment(data.start_datetime).format("YYYY-MM-DD"));
  const [timeSlotOptions, setTimeSlotOptions] = useState([]);

  console.log("selectedDate", selectedDate);

  // var splittedDate = data.start_datetime.split("T");
  // var splittedStartTime = data.start_datetime.split("T");
  // var newStartTime = splittedStartTime[1].split(":00.");
  // var splittedEndTime = data.end_datetime.split("T");
  // var newEndTime = splittedEndTime[1].split(":00.");

  let [bookingDate, setBookingDate] = useState();
  const [stratTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    getAllEmployees();
    getAllServices();
    var splittedDate = data.start_datetime.split("T");
    var splittedStartTime = data.start_datetime.split("T");
    var newStartTime = splittedStartTime[1].split(":00.");
    var splittedEndTime = data.end_datetime.split("T");
    var newEndTime = splittedEndTime[1].split(":00.");

    setBookingDate((bookingDate = splittedDate[0]));
    setStartTime(newStartTime[0]);
    setEndTime(newEndTime[0]);
  }, [isServiceUpdated]);

  useEffect(() => {
    setOpeningTime((openingTime = opening_time));
    setClosingTime((closingTime = closing_time));
    // if (opening_time.includes("M"))
    //   setOpeningTime(
    //     (openingTime = convertDurationToTime(opening_time, true).slice(0, 5))
    //   );
    // else
    //   setOpeningTime(
    //     (openingTime = convertDurationToTime(opening_time, false).slice(0, 5))
    //   );
    // if (closing_time.includes("M"))
    //   setClosingTime(
    //     (closingTime = convertDurationToTime(closing_time, true).slice(0, 5))
    //   );
    // else
    //   setClosingTime(
    //     (closingTime = convertDurationToTime(closing_time, false).slice(0, 5))
    //   );
  }, [isDateChanged]);

  // const convertDurationToTime = (duration, useMinutes) => {
  //   let time = new Date(0);
  //   let [hours, minutes] = [0, 0];
  //   if (useMinutes) {
  //     [hours, minutes] = duration.match(/PT(\d+)H(\d+)M/).slice(1);
  //     time.setHours(hours);
  //     time.setMinutes(minutes);
  //   } else {
  //     [hours, minutes] = duration.match(/PT(\d+)H/).slice(1);
  //     time.setHours(hours);
  //   }
  //   return time.toTimeString();
  // };

  //#region time slots functions
  const addHours = (date) => {
    var time = new Date("2022-01-01 " + date);
    var updatedTime = new Date(time.getTime() + interval_time * 60000);
    return moment(updatedTime).format("HH:mm");
  };

  const addMinutes = (time, minutes) => {
    var date = new Date(new Date("01/01/2015 " + time).getTime() + minutes * 60000);
    var tempTime =
      (date.getHours().toString().length == 1 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes());
    return tempTime;
  };

  const disablePastTime = (newDate) => {
    const disabled = slotData.filter((item, index) => {
      const timeSlotTime = item;
      if (moment(today).format("YYYY/MM/DD") == moment(selectedDate).format("YYYY/MM/DD")) {
        return timeSlotTime > newDate;
      } else {
        return timeSlotTime;
      }
    });
    setUpdatedSlots((updatedSlots = disabled));
    createTimeSlotsOptions(updatedSlots);
  };

  const createTimeSlotsOptions = (updatedSlots) => {
    let newArray = [];
    for (let index = 0; index < updatedSlots.length; index++) {
      const element = updatedSlots[index];
      let object = {
        label: element + " - " + addHours(element),
        value: element + " - " + addHours(element),
      };
      newArray.push(object);
    }
    setTimeSlotOptions(newArray);
  };

  useEffect(() => {
    var timeslots = [];

    var newDate = moment(date).format("HH:mm");
    while (openingTime < closingTime) {
      timeslots.push(openingTime);
      openingTime = addMinutes(openingTime, interval_time);
    }
    setSlotData((slotData = timeslots));
    disablePastTime(newDate);

    // setUpdatedSlots((updatedSlots = slotData));
  }, [isDateChanged]);
  //#endregion

  const getAllEmployees = () => {
    // const todayDate = new Date();
    let currentDay = new Date(selectedDate).getDay();
    currentDay += 1;

    let obj = {
      getAvailableEmployeesModel: {
        branch_id: parseInt(branch_id),
        end: data.end_datetime,
        limit: 500,
        page: 1,
        service_id: data.service_id,
        start: data.start_datetime,
        day: currentDay,
      },
    };
    getAllAvailableEmployees(obj)
      .then((res) => {
        let newArray = [];
        if (res.data.data.getFreeEmployees.error_code === 0) {
          for (
            let index = 0;
            index < res.data.data.getFreeEmployees.result[0].employees.length;
            index++
          ) {
            const element = res.data.data.getFreeEmployees.result[0].employees[index];
            element.value = element.user_id;
            element.label = element.first_name + " " + element.last_name;
            newArray.push(element);
          }
          setEmployees(newArray);
        } else setEmployees([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllServices = () => {
    getAllAvailableServices(branch_id, 0, 1000, 1)
      .then((res) => {
        if (res.data.data.getAllServices.error_code === 0) {
          setServices(res.data.data.getAllServices.result);
        } else setServices([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="modify_booking_card">
        <div className="modify_booking_service_number">Service {index + 1}</div>

        <div className="modify_booking_card_content">
          <input className="service_name_dropdown" type="text" value={data.service_name} disabled />

          <input
            className="service_name_dropdown"
            type="date"
            min={moment(new Date()).format("YYYY-MM-DD")}
            max={moment(new Date()).add(1, "day").format("YYYY-MM-DD")}
            value={bookingDate}
            name="start_datetime"
            onChange={(e) => {
              setIsDateChanged(!isDateChanged);
              setSelectedDate((selectedDate = e.currentTarget.value));
              console.log("e", e.currentTarget.value);
              handleValuesChange(e, index, "", data.start_datetime, data.end_datetime);
            }}
          />

          <Select
            placeholder="Time slots"
            options={timeSlotOptions}
            name="start_datetime"
            value={{
              label: stratTime + " - " + endTime,
              value: stratTime + " - " + endTime,
            }}
            className="book_app_customer_input"
            onChange={(e) => {
              e.name = "start_datetime";
              handleValuesChange(e, index, "time", data.start_datetime);
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary75: "#2a5e59",
                primary25: "#2a5e595e",
                primary50: "#2a5e595e",
                primary: "#2a5e59",
              },
            })}
            styles={{
              control: (base, state) => ({
                ...base,
                "&:hover": {
                  borderColor: "transparent",
                  borderBottom: "1px solid #DFDFDF",
                  borderBottomLeftRadius: "0",
                  borderBottomRightRadius: "0",
                }, // border style on hover
                border: "1px solid transparent", // default border color
                boxShadow: "none", // no box-shadow
                borderRadius: "20px",
                marginRight: "0.5em",
                background: "#EFEFEF",
                borderBottom: "1px solid #DFDFDF",
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
                fontFamily: "Montserrat",
              }),
            }}
          />
          <Select
            placeholder="Employee"
            options={employees}
            value={{ label: data.employee_name, value: data.employee_user_id }}
            onChange={(e) => {
              e.name = "employee_name";
              handleValuesChange(e, index, "employee");
            }}
            name="employee_name"
            className="book_app_customer_input"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary75: "#2a5e59",
                primary25: "#2a5e595e",
                primary50: "#2a5e595e",
                primary: "#2a5e59",
              },
            })}
            styles={{
              control: (base, state) => ({
                ...base,
                "&:hover": {
                  borderColor: "transparent",
                  borderBottom: "1px solid #DFDFDF",
                  borderBottomLeftRadius: "0",
                  borderBottomRightRadius: "0",
                }, // border style on hover
                border: "1px solid transparent", // default border color
                boxShadow: "none", // no box-shadow
                borderRadius: "20px",
                marginRight: "0.5em",
                background: "#EFEFEF",
                borderBottom: "1px solid #DFDFDF",
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
                fontFamily: "Montserrat",
              }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ModifyBookingCard;

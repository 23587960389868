import "./Navbar.css";
import FlagImage from "../../img/usflag.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import logo from "../../img/logo.png"

const Navbar = ({ islogin }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("receptionistId");
  const { pathname } = useLocation();

  useEffect(() => {
    // if (user) navigate("/receptionist_dashboard");
  }, []);

  return (
    <>
      <div className="navbar">
        <div className="navebar_spaname">
          {/* <span className="navbar_spaname_style"> SALOON </span>
          SERVICES */}
          <img src={logo} style={{width:"20%"}}/>
        </div>

        {user ? (
          <div className="navbar_links">
            <div
              className="navbar_links_style"
              onClick={(event) => {
                navigate("/receptionist_dashboard");
              }}
              style={
                pathname == "/receptionist_dashboard"
                  ? { textShadow: "0 0 .65px #2b3738, 0 0 .65px #2b3738" }
                  : null
              }
            >
              Schedule
            </div>
            <div
              className="navbar_links_style"
              onClick={(event) => {
                localStorage.removeItem("pg");
                localStorage.removeItem("pre");
                navigate("/book_appointment");
              }}
              style={
                pathname == "/book_appointment" ? { textShadow: "0 0 .65px #2b3738, 0 0 .65px #2b3738" } : null
              }
            >
              Book Appointment
            </div>
            <div
              className="navbar_links_style"
              onClick={(event) => {
                localStorage.removeItem("pg");
                localStorage.removeItem("pre");
                navigate("/allemployees");
              }}
              style={pathname == "/allemployees" ? { textShadow: "0 0 .65px #2b3738, 0 0 .65px #2b3738" } : null}
            >
              Employees
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="navbar_buttons">
          <button
            className="navbar_join_btn"
            onClick={(event) => {
              if (localStorage.getItem("receptionistId")) {
                localStorage.clear();
                navigate("/");
              } else {
                navigate("/");
              }
            }}
          >
            {user ? "Logout" : "Join Now"}
          </button>

          {user ? (
            ""
          ) : (
            <div className="navbar_dropdownbox">
              <img src={FlagImage} alt="flag" />
              <select name="language" id="language" className="navbar_dropdown">
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;

import axios from "axios";

export async function LoginApiCall(login) {
  console.log("login", login);
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
        mutation($login: LoginVMInput!){
            login(model: $login){
              error_code,
              message,
              result {
                id,
                phone_number,
                user_role,
               employees{
                  first_name,
                  last_name,
                }
                name,
                branch {
                  id,
                  name,
                  opening_time,
                  closing_time,
                  slot_interval,
                  type,
                }
              }
            }
          }
    `,
    variables: login,
  };
  // let response = null;
  return axios({
    url: process.env.REACT_APP_BASEURL,
    // url: route,
    method: "POST",
    data: graphqlQuery,
  });
}

export async function GetBookingsApiCall(bookings) {
  console.log("getbookings", bookings);
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
        query($getbookings: GetBookingsVMInput!){
            getBookings (model: $getbookings) {
              error_code,
              message,
              total_records,
              page,
              result {
                id,
                created_on,
                customer_name,
                customer_phone,
                customer_image,
                payment_status_id,
                net_price,
                booking_type_id,
                vat,
                discount,
                price,
                status_id,
                user_id,
                discount_code,
                payment_type_id,
                booking_details{
                  id,
                  service_name,
                  service_id,
                  employee_name,
                  employee_user_id,
                  booking_id,
                  start_datetime,
                  end_datetime,
                  price
          
                }
          
              }
              
            }
          }
    `,
    variables: bookings,
  };
  // let response = null;
  return axios({
    url: process.env.REACT_APP_BASEURL,
    // url: route,
    method: "POST",
    data: graphqlQuery,
  });
}

export const getAllAvailableEmployees = (data) => {
  console.log("data", data);
  const graphqlQuery = {
    query: `query($getAvailableEmployeesModel: FreeEmployeesVMInput!){
      getFreeEmployees(model: $getAvailableEmployeesModel){
      error_code,
      success,
      status,
      message,
      total_records,
      page,
      result {
        id,
        user_role,
        name,
        image,
        rating,
        employees{
          id,
          first_name,
          last_name,
          rating,
          description,
          image,
          user_id
        }
      }
      }
    }`,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getAllAvailableServices = (branchId, categoryId, limit, page) => {
  const graphqlQuery = {
    query: `query{
      getAllServices(branchId: ${branchId},categoryId: ${categoryId},limit:${limit}, page: ${page}  ){
      error_code,
      success,
      status,
      message,
      total_records,
      page,
      result {
          id,
          name,
          name_ar,
          description,
          description_ar,
          image,
          category_id,
          branch_id,
          price,
          executive_price,
          time,
        }
      }
    }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const addNewBooking = (bookingObj) => {
  const graphqlQuery = {
    query: `mutation($bookingModel : BookingVMInput!){
      postBooking(model: $bookingModel) {
        error_code,
        success,
        message,
      }
    }
    `,
    variables: bookingObj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getAllAvailableCustomers = (branchId, limit, page) => {
  const graphqlQuery = {
    query: `query{
      getAllUsers(branchId: ${branchId},limit:${limit}, page: ${page}  ){
     error_code
    success
    status
    message
    result {
      id
      user_role
      phone_number
      name
    }
      }
    }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const validateCoupon = (branchId, coupon, date) => {
  const graphqlQuery = {
    query: ` query{
      validateCoupon(branchId: ${branchId},coupon: "${coupon}", currentDateTime: "${date}" ) {
        error_code,
        message,
        success,
        result {
          amount,
          type,
        }
      }
      }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const getAllEmployees = (branchId, serviceId, limit, page) => {
  const graphqlQuery = {
    query: `query{
      getAllEmployees(branchId: ${branchId},serviceId: ${serviceId},limit:${limit}, page: ${page}  ){
      error_code,
      success,
      status,
      message,
      total_records,
      page,
       result {
      id
      employees{
        id
        status
        first_name
        first_name_ar
        last_name
        last_name_ar
      }
    }
      }
    }`,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const changeBookingStatus = (bookingObj) => {
  const graphqlQuery = {
    query: `mutation($paymentModel : ChangeBookingStatusVMInput!){
      changeBookingStatus(model: $paymentModel) {
        error_code,
        success,
        message,
      }
    }
    `,
    variables: bookingObj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export async function getAllEmployeeSchedule(object) {
  console.log("object", object);
  let route = process.env.REACT_APP_BASEURL;
  const graphqlQuery = {
    query: `
  query($Model : AllEmployeesScheduleVMInput!){
    getAllEmployeeSchedule(model: $Model) {
      error_code,
      message,
      result {
        name,
        is_free
      }
    }
  }
`,
    variables: object,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
}

export const getVatRateById = (id) => {
  const params = {};
  if (id) {
    params.id = id;
  }
  return axios.get(
    process.env.REACT_APP_BASEURL_ONLY + "api/organization/get",
    {
      params,
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const customerSignup = (data) => {
  const graphqlQuery = {
    query: `
    mutation($signupModel : UserVMInput!){
        postUser(model: $signupModel) {
          error_code,
          message,
          success,
          result {
            id,
            name,
            phone_number,
            image,
            code,
            code_generated_on,
          }
        }
      }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export const verifyCode = (data) => {
  const graphqlQuery = {
    query: `mutation($verifyCodeModel : VerifyCodeVMInput!){
      verifyCode(model: $verifyCodeModel ) {
        error_code,
        message,
        success,
        result {
          id,
          image,
          name,
          phone_number,
      
        }
      }
    }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

export async function checkNextIntervalOfCurrentBooking(obj) {
  const graphqlQuery = {
    query: `
query($model: CheckEmployeeVMInput!)
{
  checkFreeEmployee (model : $model){
    error_code,
    success,
    message,
  }
}
  `,
    variables: obj,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
}

export const deleteBooking = (data) => {
  const graphqlQuery = {
    query: `mutation($ID : IdViewModelInput!){
      deleteBooking(model: $ID ) {
        error_code,
        message,
        success,
      }
    }
    `,
    variables: data,
  };
  let response = null;
  return (response = axios({
    url: process.env.REACT_APP_BASEURL,
    method: "POST",
    data: graphqlQuery,
  }));
};

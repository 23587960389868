import { useNavigate } from "react-router-dom";
import "./TableRow.css";
import moment from "moment/moment";
import { useState } from "react";
import { changeBookingStatus } from "../../../api/apicalls";
// import 'System.Globalization'

const TableRow = ({ data, onDelete, isDeleteLoading }) => {
  const [isLoadingRefund, setIsLoadingRefund] = useState(false);
  const [bookingData, setBookingData] = useState(data);

  const navigate = useNavigate();
  const setDate = (data) => {
    var splittedDate = data.start_datetime.split("T");
    return splittedDate[0];
  };

  const setTime = (data) => {
    var splittedStartTime = data.start_datetime.split("T");
    var newStartTime = splittedStartTime[1].split(":00.");
    var splittedEndTime = data.end_datetime.split("T");
    var newEndTime = splittedEndTime[1].split(":00.");
    return newStartTime[0] + " - " + newEndTime[0];
    // return (
    //   moment(data.start_datetime).subtract(13, "hours").format("HH:mm") +
    //   " - " +
    //   moment(data.end_datetime).subtract(13, "hours").format("HH:mm")
    // );
  };

  const onRefundClick = () => {
    let requestObj = {
      paymentModel: {
        id: bookingData.id,
        payment_type_id: 10,
        payment_status_id: 22,
      },
    };
    setIsLoadingRefund(true);
    changeBookingStatus(requestObj)
      .then(({ data }) => {
        setIsLoadingRefund(false);
        if (data.data.changeBookingStatus.error_code == 0) {
          let newData = { ...bookingData };
          newData.payment_status_id = 22;
          setBookingData(newData);
        }
      })
      .catch((err) => {
        setIsLoadingRefund(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <tr className="table_row">
        <td className="table_data">
          {setDate(bookingData.booking_details[0])}
        </td>
        <td className="table_data">
          {setTime(bookingData.booking_details[0])}
        </td>
        <td className="table_data">{bookingData.customer_name}</td>
        <td
          className={
            bookingData.payment_status_id === 13
              ? "table_data table_data_paid_status_style"
              : "table_data table_data_unpaid_status_style"
          }
          style={{
            color: bookingData.payment_status_id === 22 ? "#1c5c42" : "",
          }}
        >
          {bookingData.payment_status_id === 13
            ? "Paid"
            : bookingData.payment_status_id === 22
            ? "Refunded"
            : "Unpaid"}
        </td>
        <td className="table_data">
          {bookingData.booking_details[0].service_name +
            " | " +
            bookingData.booking_details[0].employee_name}
        </td>
        <td
          className="table_data"
          style={{ textAlign: "start", paddingLeft: "1.5em", display:"flex", minWidth:"fit-content" }}
        >
          {" "}
          <button
            onClick={(event) => {
              navigate("/appointmentdetails", {
                state: {
                  datatosend: bookingData,
                },
              });
            }}
          >
            See Details
          </button>{" "}
          {bookingData.payment_status_id !== 13 ?
            <button
              onClick={() => {
                onDelete(data)
              }}
              style={{background:"black"}}
              disabled={isDeleteLoading}
            >
              {"Cancel Booking" }
            </button>
             : null
          }
          {/* {bookingData.payment_status_id !== 13 ? null : (
            <button
              className="inv_paynow_btn"
              onClick={() => {
                onRefundClick();
              }}
              disabled={isLoadingRefund}
            >
              {isLoadingRefund ? "Processing.." : "Refund"}
            </button>
          )} */}
        </td>
        {/* <td className="table_data">
          {bookingData.payment_status_id !== 13 ? null : (
            <button
              className="inv_paynow_btn"
              onClick={() => {
                onRefundClick();
              }}
              disabled={isLoadingRefund}
            >
              {isLoadingRefund ? "Processing.." : "Refund"}
            </button>
          )}
        </td> */}
      </tr>
    </>
  );
};

export default TableRow;

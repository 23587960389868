import InvoiceRow from "./InvoiceRow/InvoiceRow";
import "./Invoice.css";
import React, { createRef, useEffect, useRef, useState } from "react";
import InvoicePayment from "./InvoicePayment/InvoicePayment";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  changeBookingStatus,
  getVatRateById,
  validateCoupon,
} from "../../api/apicalls";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";

const Invoice = () => {
  const [paymentpopup, setpaymentpopup] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state ? location.state.datatosend : "";
  let today = new Date();
  today = moment(today).format("DD/MM/YY");
  const pdfExportComponent = useRef(null);
  let [price, setPrice] = useState(data.price);
  let [netPrice, setNetPrice] = useState("");
  let [vat, setVat] = useState(0);
  // let vatPercentage = 0.15;
  const [coupon, setCoupon] = useState("");
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  let [discount, setDiscount] = useState(0);
  const branch_id = localStorage.getItem("branchId");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRefund, setIsLoadingRefund] = useState(false);

  let [vatPercentage, setVatPercentage] = useState(0);

  useEffect(() => {
    fetchVatRate();
  }, []);

  console.log("data", data)

  const generatePDFFile = (event) => {
    pdfExportComponent.current.save();
  };

  const validateCouponAPI = () => {
    if (!isNullOrEmpty(coupon)) {
      setIsLoading(true);
      let date = moment(new Date()).format("YYYY-MM-DDTHH:mm");
      let recievedPrice = data.price;
      let recievedNetPrice = data.net_price;
      validateCoupon(branch_id, coupon, date)
        .then(({ data }) => {
          setIsLoading(false);

          if (data.data.validateCoupon.error_code == 0) {
            setPrice((price = recievedPrice));
            let newNetPrice = recievedNetPrice;
            setNetPrice((netPrice = ""));
            setVat((vat = price * vatPercentage));
            console.log("result", data.data.validateCoupon.result);
            if (data.data.validateCoupon.result.type == 0) {
              setNetPrice(newNetPrice - data.data.validateCoupon.result.amount);
              setDiscount(data.data.validateCoupon.result.amount);
            } else {
              setDiscount(
                (discount =
                  price * (data.data.validateCoupon.result.amount / 100))
              );
              setNetPrice((netPrice = price + vat - discount));
            }
            setCoupon("");
          } else setCouponErrorMessage(data.data.validateCoupon.message);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err", err);
        });
    } else setCouponErrorMessage("Please enter coupon");
  };

  const fetchVatRate = () => {
    getVatRateById(1)
      .then(({ data }) => {
        console.log("data", data);
        if (data.error_code == 0)
          setVatPercentage((vatPercentage = data.result.vat));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const calculateDiscountPercentage = () => {
    var result = (parseFloat(coupon) / 100) * data.net_price;
    var price = data.net_price - result;
    setNetPrice(price);
  };

  const onRefundClick = () => {
    let requestObj = {
      paymentModel: {
        id: data.id,
        payment_type_id: 10,
        payment_status_id: 22,
      },
    };
    setIsLoadingRefund(true);
    changeBookingStatus(requestObj)
      .then(({ data }) => {
        setIsLoadingRefund(false);
        if (data.data.changeBookingStatus.error_code == 0) {
          navigate("/receptionist_dashboard");
        }
      })
      .catch((err) => {
        setIsLoadingRefund(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <InvoicePayment
        paymentpopup={paymentpopup}
        setpaymentpopup={setpaymentpopup}
        netAmount={netPrice ? netPrice : data.net_price}
        bookingId={data.id}
      />
      <div className="cover_image">
        {/* <img src={ScheduleImage} alt="" /> */}
        <p className="reception_heading">Schedule</p>
      </div>

      {/* {paymentpopup? <InvoicePayment paymentpopup = {paymentpopup} setpaymentpopup = {setpaymentpopup}/> :""} */}
      <div className="invoice_main_container">
        <PDFExport ref={pdfExportComponent} fileName="Invoice" margin={"50pt"}>
          <div className="invoice_main_container__wrapper">
            <div className="invoice_heading_container">
              <div className="invoice_heading_style">Invoice</div>
              <div className="invoice_number_style">
                #{data ? data.user_id + "" + data.id : ""}
              </div>
            </div>

            <div className="invoice_header_details_1">
              <div>
                <div className="inv_head_headings_style">Order</div>
                <div className="inv_head_content_style">
                  {data ? data.id : ""}
                </div>
              </div>

              <div className="inv_head_2">
                <div className="inv_head_headings_style">Date</div>
                <div className="inv_head_content_style">{today}</div>
              </div>
            </div>

            <div className="invoice_header_details_1">
              <div>
                <div className="inv_head_headings_style">Branch</div>
                <div className="inv_head_content_style">
                  {localStorage.getItem("branchName")}
                </div>
              </div>

              <div className="inv_head_2">
                <div className="inv_head_headings_style">Receptionist</div>
                <div className="inv_head_content_style">
                  {localStorage.getItem("receptionistName")}
                </div>
              </div>
            </div>

            <div className="invoice_head">
              <div className="">Service</div>
              <div className="inv_box_align">
                <div className="">Price</div>
                {/* <div className="">Qty</div>
                <div className="">Amount</div> */}
              </div>
            </div>

            <div className="invoice_content">
              {data.booking_details.map((item) => (
                <InvoiceRow data={item} />
              ))}
            </div>

            <hr
              style={{
                background: "#0000000D",
                marginTop: "1.5em 0em",
                width: "85%",
              }}
            />

            <div className="inv_subtotal_container">
              <div className="inv_subtotal_heading">Subtotal</div>
              <div className="inv_subtotal_amount">{price} SAR</div>
            </div>

            <div className="inv_subtotal_container">
              <div className="inv_subtotal_heading">VAT</div>
              <div className="inv_subtotal_amount">{data.vat} SAR</div>
            </div>
            <div className="inv_subtotal_container">
              <div className="inv_subtotal_heading">Discount</div>
              <div className="inv_subtotal_amount">{data.discount} SAR</div>
            </div>

            <div className="inv_subtotal_container">
              <div className="inv_subtotal_heading">Total</div>
              <div
              // style={{ display: "flex", alignItems: "center" }}
              >
                <div className="inv_total_amount">
                  {netPrice ? parseInt(netPrice) + " SAR" : ""}
                </div>
                <div
                  className={
                    netPrice ? "inv_total_discount_amount" : "inv_total_amount"
                  }
                >
                  {parseInt(data.net_price)} SAR
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
        {data.payment_status_id == 13 ||
        data.payment_status_id === 22 || data.discount > 0 ? null : (
          <div className="inv_coupon_container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <input
                type="text"
                name=""
                id=""
                placeholder="Enter  coupon"
                className="inv_coupon_input"
                onChange={(e) => {
                  setCoupon(e.currentTarget.value);
                  setCouponErrorMessage("");
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    if (!isNullOrEmpty(netPrice))
                      setCouponErrorMessage(
                        "You have availed the discount already"
                      );
                    else validateCouponAPI();
                  }
                }}
              />
              <div
                style={{ fontSize: "12px", color: "red", marginLeft: "1em" }}
              >
                {couponErrorMessage}
              </div>
            </div>
            <button
              className="inv_coupon_btn"
              onClick={() => {
                if (!isNullOrEmpty(netPrice))
                  setCouponErrorMessage(
                    "You have availed the discount already"
                  );
                else validateCouponAPI();
              }}
            >
              {isLoading ? "----" : "Apply"}
            </button>
          </div>
        )}

        <div className="inv_buttons">
          <button className="inv_download_btn" onClick={generatePDFFile}>
            Download pdf
          </button>
          {data.payment_status_id === 13 ||
          data.payment_status_id === 22 ? null : (
            <button
              className="inv_paynow_btn"
              onClick={() => {
                setpaymentpopup(true);
              }}
            >
              Generate invoice
            </button>
          )}
          {/* {data.payment_status_id !== 13 ? null : (
            <button
              className="inv_paynow_btn"
              onClick={() => {
                onRefundClick();
              }}
              disabled={isLoadingRefund}
            >
              {isLoadingRefund ? "Processing.." : "Refund"}
            </button>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Invoice;

import { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import "./BookAppointment.css";
import LeafImage1 from "../../img/book_app_leaf_1.png";
import LeafImage2 from "../../img/book_app_leaf_2.png";
import LeafImage3 from "../../img/receptionleaf.png";

import Step1Seleced from "../../img/book_app_step1_selected.png";
import Step2Seleced from "../../img/book_app_step2_selected.png";
import Step3Seleced from "../../img/book_app_step3_selected.png";

import Step1UnSeleced from "../../img/book_app_step1_unselected.png";
import Step2UnSeleced from "../../img/book_app_step2_unselected.png";
import Step3UnSeleced from "../../img/book_app_step3_unselected.png";

import SearchIcon from "../../img/book_app_customer_search_icon.png";
import BookAppServiceLine from "../../img/book_app_service_line.png";
import SearchIconWhite from "../../img/book_app_customer_search_icon_white.png";
import CloseIcon from "../../img/book_app_customer_search_close.png";

import BookAppBookingsRow from "./BookAppBookingsRow/BookAppBookingsRow";
import { useNavigate } from "react-router-dom";
import BookAppCalender from "./BookAppCalender/BookAppCalender";
import BookAppCustomerSearchResults from "./BookAppCustomerSearchResults/BookAppCustomerSearchResults";
import Select from "react-select";
import {
  addNewBooking,
  checkNextIntervalOfCurrentBooking,
  customerSignup,
  getAllAvailableCustomers,
  getAllAvailableEmployees,
  getAllAvailableServices,
  verifyCode,
} from "../../api/apicalls";
import moment from "moment";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";
import { toast, Toaster } from "react-hot-toast";
import { CustomError } from "../Toast";
import { isOnlyNumber } from "../../Utils/isOnlyNumber";
// import { useScrollTo } from 'react-use-window-scroll';
// import TableRow from '../Receptionist Dashboard/TableRow/TableRow';

const BookAppointment = () => {
  const navigate = useNavigate();
  const branch_id = localStorage.getItem("branchId");
  const slotInterval = localStorage.getItem("slotInterval");
  const cartProducts = localStorage.getItem("cartProducts");
  const user = localStorage.getItem("customer");
  let opening_time = localStorage.getItem("openingTime");
  let closing_time = localStorage.getItem("closingTime");
  let [openingTime, setOpeningTime] = useState("");
  let [closingTime, setClosingTime] = useState("");
  const [iswalkin, setiswalkin] = useState(true);
  const [step, setstep] = useState("1");
  const [issearch, setissearch] = useState(false);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [cartArray, setCartArray] = useState(cartProducts ? JSON.parse(cartProducts) : []);
  const [phone, setPhone] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState(user ? JSON.parse(user) : "");
  const [intervalTime, setIntervalTime] = useState(0);
  const [selectedService, setSelctedService] = useState("");
  const [selectedDate, setSelctedDate] = useState(new Date());
  let [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  let [slotData, setSlotData] = useState([]);
  let [updatedSlots, setUpdatedSlots] = useState([]);
  let [selectedTime, setSelectedTime] = useState("");
  let [selectedStartTime, setSelectedStartTime] = useState("");
  let [selectedEndTime, setSelectedEndTime] = useState("");
  const [code, setCode] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [name, setName] = useState("");
  const [day, setDay] = useState("");

  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState("");
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState("");
  const [customerErrorMessage, setCustomerErrorMessage] = useState("");
  const [serviceErrorMessage, setServiceErrorMessage] = useState("");
  const [employeeErrorMessage, setEmployeeErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);

  const userId = localStorage.getItem("receptionistId");
  let today = new Date();
  let date = new Date();

  useEffect(() => {
    if (!userId) navigate("/");
  }, []);

  useEffect(() => {
    setOpeningTime((openingTime = opening_time));
    setClosingTime((closingTime = closing_time));
    // if(selectedDate){
    //
    //   let splittedDate = selectedDate.toString().split(" ")
    //
    //   if(splittedDate[0] == "Mon")
    //   setDay(2);
    //   else if(splittedDate[0] == "Tue")
    //   setDay(3);
    //   else if(splittedDate[0] == "Wed")
    //     setDay(4);
    //   else if(splittedDate[0] == "Thu")
    //     setDay(5);
    //   else if(splittedDate[0] == "Fri")
    //     setDay(6);
    //   else if(splittedDate[0] == "Sat")
    //     setDay(7);
    //   else
    //     setDay(1);
    // }
  }, [selectedDate]);
  useEffect(() => {
    //
    selectedService != "" ? setIntervalTime(selectedService.time) : setIntervalTime(0);
  }, [selectedService]);
  useEffect(() => {
    console.log("intervalTime", intervalTime);
  }, [intervalTime]);

  useEffect(() => {
    getAllServices();
    getAllCustomer();
  }, []);

  const getAllServices = () => {
    getAllAvailableServices(branch_id, 0, 1000, 1)
      .then((res) => {
        let newArray = [];
        if (res.data.data.getAllServices.error_code === 0) {
          for (let index = 0; index < res.data.data.getAllServices.result.length; index++) {
            const element = res.data.data.getAllServices.result[index];
            element.value = element.id;
            element.label = element.name;
            newArray.push(element);
          }
          setServices(newArray);
        } else setServices([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllEmployees = (id) => {
    let currentDay = moment(selectedDate).day() + 1;
    console.log("currentDay", currentDay);
    debugger;
    var time = selectedTime.split(" - ");
    let obj = {
      getAvailableEmployeesModel: {
        branch_id: parseInt(branch_id),
        end: moment(selectedDate).format("YYYY-MM-DD") + "T" + time[1] + ":00+00:00",
        limit: 500,
        page: 1,
        service_id: id,
        start: moment(selectedDate).format("YYYY-MM-DD") + "T" + time[0] + ":00+00:00",
        day: currentDay,
      },
    };
    setIsEmployeeLoading(true);
    getAllAvailableEmployees(obj)
      .then((res) => {
        let newArray = [];
        setIsEmployeeLoading(false);
        if (res.data.data.getFreeEmployees.error_code === 0) {
          for (let index = 0; index < res.data.data.getFreeEmployees.result.length; index++) {
            const element = res.data.data.getFreeEmployees.result[index];
            element.value = element.employees[0].user_id;
            element.label = element.employees[0].first_name + " " + element.employees[0].last_name;
            newArray.push(element);
          }
          setEmployees(newArray);
        } else setEmployees([]);
      })
      .catch((err) => {
        setIsEmployeeLoading(false);
        console.log("err", err);
      });
  };

  const getAllCustomer = () => {
    getAllAvailableCustomers(0, 10000, 1)
      .then((res) => {
        let newArray = [];
        if (res.data.data.getAllUsers.error_code === 0) {
          for (let index = 0; index < res.data.data.getAllUsers.result.length; index++) {
            const element = res.data.data.getAllUsers.result[index];
            element.value = element.id;
            element.label = element.name + " - " + element.phone_number;
            newArray.push(element);
          }
          setCustomers(newArray);
        } else setCustomers([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const isTimeValid = () => {
    let newStartTime = moment(selectedDate).format("YYYY-MM-DD") + "T" + startTime;
    let newEndTime = moment(selectedDate).format("YYYY-MM-DD") + "T" + endTime;
    var diff = Math.abs(new Date(newStartTime) - new Date(newEndTime));
    var minutes = Math.floor(diff / 1000 / 60);

    var time2 = moment(startTime, "HH:mm");
    var time1 = moment(endTime, "HH:mm");
    var formatedCurrentTime = moment(new Date()).format("HH:mm");
    var currnetTime = moment(formatedCurrentTime, "HH:mm");
    var duration = time2.isSameOrAfter(time1);
    var isGreaterThanCurrent = time2.isSameOrBefore(currnetTime);
    if (startTime == "") setStartTimeErrorMessage("Please select start time");
    else if (endTime == "") setEndTimeErrorMessage("Please select end time");
    else if (moment(selectedDate).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")) {
      if (isGreaterThanCurrent == true)
        setStartTimeErrorMessage("Start time can't be < than current time");
      else if (duration == true) {
        setEndTimeErrorMessage("End time can't be > start time");
      } else if (minutes > intervalTime)
        setEndTimeErrorMessage("Duration should be " + intervalTime + " minutes");
      else return true;
    } else if (duration == true) {
      setEndTimeErrorMessage("End time can't be > start time");
    } else if (minutes > intervalTime)
      setEndTimeErrorMessage("Duration should be " + intervalTime + " minutes");
    else return true;
    return false;
  };

  const isStep1Valid = () => {
    if (iswalkin && isNullOrEmpty(name)) setNameErrorMessage("Please enter name");
    else if (iswalkin && isNullOrEmpty(phone)) setPhoneErrorMessage("Please enter phone number");
    else if (!iswalkin && isNullOrEmpty(selectedCustomer))
      setCustomerErrorMessage("Please select customer");
    else if (isNullOrEmpty(selectedService)) setServiceErrorMessage("Please select service");
    else return true;
    return false;
  };

  const isStep3Valid = () => {
    if (isNullOrEmpty(selectedEmployee)) setEmployeeErrorMessage("Please select employee");
    else return true;
    return false;
  };

  const onNextClick = () => {
    debugger;
    if (step === "1") {
      if (isStep1Valid()) setstep("2");
    } else if (step === "2") {
      if (!isNullOrEmpty(selectedTime)) {
        setstep("3");
        getAllEmployees(selectedService?.value);
      }
    }
  };

  const onAddMoreClick = () => {
    let vatPercentage = 0.15;
    if (isStep3Valid()) {
      var time = selectedTime.split(" - ");
      var newArray = [...cartArray];
      let cartObject = {
        id: cartArray.length + 1,
        booking_id: 0,
        employee_user_id: parseInt(selectedEmployee.value),
        employee_name: selectedEmployee.label,
        service_id: parseInt(selectedService.id),
        service_name: selectedService.name,
        customer_user_id: customerId ? customerId : parseInt(selectedCustomer.id),
        price: selectedService.price,
        is_executive: 0,
        start_datetime: moment(selectedDate).format("YYYY-MM-DD") + "T" + time[0],
        end_datetime: moment(selectedDate).format("YYYY-MM-DD") + "T" + time[1],
        status_id: 3,
        is_disabled: false,
        intervalTime: intervalTime,
      };

      newArray.push(cartObject);
      setCartArray(newArray);
      localStorage.setItem("customer", JSON.stringify(selectedCustomer));
      localStorage.setItem("cartProducts", JSON.stringify(newArray));

      setSelctedService("");
      // setIntervalTime(0);
      setStartTime("");
      setEndTime("");
      setSelectedEmployee("");
      setstep("1");
    }
  };

  const onDeleteClick = (item, index) => {
    // const filteredData = cartArray.filter((element) => element.id !== item.id);

    let newData = [...cartArray];
    const filteredItems = newData.filter((element) => element.service_id == item.service_id);
    if (filteredItems.length >= 2) {
      var filteredData = newData.filter((element) => element.id !== item.id);
      var found = filteredData.findLastIndex((element) => element.service_id == item.service_id);
      if (found >= 0) newData[found].is_disabled = false;
    } else var filteredData = newData.filter((element) => element.id !== item.id);

    setCartArray(filteredData);
    if (filteredData.length > 0) localStorage.setItem("cartProducts", JSON.stringify(filteredData));
    else {
      localStorage.removeItem("cartProducts");
      localStorage.removeItem("customer");
    }
  };

  // const onCompleteButtonClick = () => {
  //   if (isStep3Valid()) {
  //     let bookingArr = [];
  //     var price = 0;
  //     let vatPercentage = 0.15;
  //     for (let index = 0; index < cartArray.length; index++) {
  //       const element = cartArray[index];
  //       let cartObject = {
  //         id: 0,
  //         booking_id: 0,
  //         employee_user_id: parseInt(element.employee_user_id),
  //         service_id: parseInt(element.service_id),
  //         customer_user_id: parseInt(element.customer_user_id),
  //         price: element.price,
  //         is_executive: 0,
  //         start_datetime: element.start_datetime,
  //         end_datetime: element.end_datetime,
  //         status_id: 3,
  //       };
  //       bookingArr.push(cartObject);
  //     }

  //     let requestObj = {
  //       bookingModel: {
  //         id: 0,
  //         user_id: parseInt(selectedCustomer.id),
  //         status_id: 3,
  //         price: price,
  //         vat: vatPercentage,
  //         discount: data.discount,
  //         discount_code: data.discount_code,
  //         net_price: netPrice,
  //         payment_type_id: 10,
  //         payment_status_id: 12,
  //         booking_type_id: 7,
  //         branch_id: parseInt(branch_id),
  //         bookingDetails: bookingArr,
  //       },
  //     };
  //     console.log("requestObj", requestObj);
  //     addNewBooking(requestObj)
  //       .then(({ data }) => {
  //         if (data.data.postBooking.error_code == 0)
  //           navigate("/receptionist_dashboard");
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // };

  const onServiceSelect = (e) => {
    setServiceErrorMessage("");

    let isValid = false;

    if (cartArray.length > 0) {
      var filteredData = cartArray.filter((element) => element.service_id == e.value);

      if (filteredData.length > 0) {
        isValid = false;
      } else isValid = true;
    } else isValid = true;

    if (isValid) {
      setSelctedService(e);
      // setIntervalTime(e.time);
    } else {
      setServiceErrorMessage("You have already added this service in your cart");
      setSelctedService("");
      // setIntervalTime(0);
    }
  };

  const onCustomerSelect = (e) => {
    if (selectedCustomer) {
      if (selectedCustomer.id !== e.id) {
        localStorage.removeItem("cartProducts");
        localStorage.removeItem("customer");
        setCartArray([]);
      }
    }
  };

  //#region time slots functions
  const addHours = (date) => {
    var time = new Date("2022-01-01 " + date);
    var newDateObj = moment(time).add(intervalTime, "m").toDate();
    var endTime = moment(newDateObj).format("HH:mm");
    var newTime = moment(new Date(time.setMinutes(time.getMinutes() + intervalTime))).format(
      "HH:mm"
    );
    return endTime;
  };

  const addMinutes = (time, minutes) => {
    var date = new Date(new Date("01/01/2015 " + time).getTime() + minutes * 60000);
    var tempTime =
      (date.getHours().toString().length == 1 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes());
    return tempTime;
  };

  const disablePastTime = (newDate) => {
    setUpdatedSlots([]);
    const disabled = slotData.filter((item, index) => {
      const timeSlotTime = item;
      if (moment(today).format("YYYY/MM/DD") == moment(selectedDate).format("YYYY/MM/DD")) {
        return timeSlotTime > newDate;
      } else {
        return timeSlotTime;
      }
    });
    setUpdatedSlots((updatedSlots = disabled));
  };

  useEffect(() => {
    console.log("slotData", slotData);
  }, [slotData]);
  useEffect(() => {
    if (intervalTime != 0) {
      console.log("gen new slots");

      var timeslots = [];
      var newDate = moment(date).format("HH:mm");
      while (openingTime < closingTime) {
        timeslots.push(openingTime);
        openingTime = addMinutes(openingTime, intervalTime);
      }
      setSlotData((slotData = timeslots));
      disablePastTime(newDate);
    }
  }, [selectedDate, intervalTime]);
  //#endregion

  const SignUp = () => {
    let PHONE_NUMBER_SAUDIA = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
    if (isNullOrEmpty(name)) setNameErrorMessage("Enter Name");
    else if (isNullOrEmpty(phone)) setPhoneErrorMessage("Enter Phone Number");
    else if (!PHONE_NUMBER_SAUDIA.test(phone)) setPhoneErrorMessage("Enter Valid Phone Number");
    else {
      let requestObj = {
        signupModel: {
          id: 0,
          name: name,
          phone_number: phone,
          role_id: 1,
        },
      };
      setIsLoading(true);
      customerSignup(requestObj)
        .then((res) => {
          setIsLoading(false);
          if (res.data.data.postUser.success) {
            // setissearch(true);
            setCustomerId(res.data.data.postUser.result.id);
            toast("Customer Registered Successfully!");
            getAllCustomer();
            // navigate("/otp", {
            //   state: {
            //     phoneNumber: requestObj.signupModel.phone_number,
            //   },
            // });
          } else {
            CustomError(res.data.data.postUser.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          CustomError("Signup Failed");
        });
    }
  };

  const verifyOtp = () => {
    if (isNullOrEmpty(code)) setCodeErrorMessage("Enter OTP");
    else {
      let requestObj = {
        verifyCodeModel: {
          phone_number: phone,
          code: code,
        },
      };
      setIsLoading(true);
      verifyCode(requestObj)
        .then((res) => {
          setIsLoading(false);
          console.log("res", res.data.data.verifyCode.result);
          if (res.data.data.verifyCode.error_code == 2) {
            setCodeErrorMessage("Invalid code");
          } else if (res.data.data.verifyCode.error_code == 0) {
            setissearch(false);
            setCustomerId(res.data.data.verifyCode.result.id);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onAddClick = (object, item, start, end, index) => {
    let cartObject = {
      id: cartArray.length + 1,
      booking_id: 0,
      employee_user_id: item.employee_user_id,
      employee_name: item.employee_name,
      service_id: item.service_id,
      service_name: item.service_name,
      customer_user_id: item.customer_user_id,
      price: item.price,
      is_executive: 0,
      start_datetime: start,
      end_datetime: end,
      status_id: 3,
      is_disabled: false,
      intervalTime: item.intervalTime,
    };

    setIsLoading(true);
    checkNextIntervalOfCurrentBooking(object)
      .then(async ({ data }) => {
        setIsLoading(false);
        if (data.data.checkFreeEmployee.error_code == 0) {
          let newData = [...cartArray];
          newData.push(cartObject);
          newData[index].is_disabled = true;
          // cartArray.push(cartObject);
          setCartArray(newData);
          localStorage.setItem("cartProducts", JSON.stringify(newData));
        } else CustomError(data.data.checkFreeEmployee.message);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
        CustomError("Some error occurred, Please try again");
      });
  };

  const onAdd = (data, index) => {
    const intervalTime = data.intervalTime;

    let currentDay = moment(selectedDate).day() + 1;
    console.log("currentDay", currentDay);

    var date = data.start_datetime.split("T");
    console.log("date", date);
    console.log("intervalTime", intervalTime);
    var startTime = moment(data.start_datetime).add(intervalTime, "minutes").format("HH:mm");
    var endTime = moment(data.end_datetime).add(intervalTime, "minutes").format("HH:mm");

    console.log("startTime + endTime", `${startTime} + ${endTime}`);

    if (endTime > closing_time) CustomError("Unable to book appointment in closing time");
    else {
      let obj = {
        model: {
          employee_user_id: data.employee_user_id,
          branch_id: parseInt(branch_id),
          service_id: data.service_id,
          start: date[0] + "T" + startTime + ":00+00:00",
          end: date[0] + "T" + endTime + ":00+00:00",
          day: currentDay,
        },
      };
      onAddClick(obj, data, date[0] + "T" + startTime, date[0] + "T" + endTime, index);
    }
  };

  return (
    <>
      <div className="billing_summary_cover_image">
        {/* <img src={ScheduleImage} alt="" /> */}
        <p className="reception_heading">Book appointment</p>
      </div>

      {/* <img src={LeafImage1} alt="leaf" className="book_app_leaf_1" />

      <img src={LeafImage3} alt="leaf" className="book_app_leaf_3" /> */}

      <div className="book_app_main_container_wrapper">
        <div className="book_app_main_container">
          <div className="book_app_customer_container" id="book_app_customer_container">
            <div className="book_app_customer_container_header">
              <div className="book_app_customer_container_heading">Fill Customer Data</div>

              {/* {iswalkin ? (
                ""
              ) : (
                <img
                  src={SearchIcon}
                  alt="search icon"
                  onClick={(event) => {
                    setissearch(true);
                  }}
                />
              )} */}
            </div>

            <div className="book_app_customer_buttons">
              <button
                className={
                  iswalkin
                    ? "book_app_customer_walkin_btn book_app_btn_selected"
                    : "book_app_customer_walkin_btn book_app_btn_unselected"
                }
                onClick={(event) => {
                  if (!iswalkin) {
                    setiswalkin(true);
                    // setSelectedCustomer("");
                  }
                }}
              >
                Walk-in Customer
              </button>
              <button
                className={
                  iswalkin
                    ? "book_app_customer_existing_btn book_app_btn_unselected"
                    : "book_app_customer_existing_btn book_app_btn_selected"
                }
                onClick={(event) => {
                  if (iswalkin) {
                    setiswalkin(false);
                    setPhone("");
                    setName("");
                  }
                }}
              >
                Existing Customer
              </button>
            </div>
            {iswalkin ? (
              <>
                <input
                  type="text"
                  placeholder="Customer Name"
                  className="book_app_customer_input"
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                    setNameErrorMessage("");
                  }}
                />
                <div className="error__message" style={{ textAlign: "start" }}>
                  {nameErrorMessage}
                </div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="book_app_customer_input"
                  value={phone}
                  onChange={(e) => {
                    if (isNullOrEmpty(e.currentTarget.value) || isOnlyNumber(e.currentTarget.value))
                      setPhone(e.currentTarget.value);
                    setPhoneErrorMessage("");
                  }}
                  style={{ marginTop: "1em" }}
                />
                <div className="error__message" style={{ textAlign: "start" }}>
                  {phoneErrorMessage}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="inv_coupon_btn"
                    onClick={() => SignUp()}
                    disabled={isLoading}
                    style={{
                      width: "50%",
                      padding: "0.5em 0",
                      marginTop: "2em",
                    }}
                  >
                    {isLoading ? "Processing..." : "Register User"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <Select
                  placeholder="Name"
                  options={customers}
                  value={selectedCustomer}
                  className="book_app_customer_input"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary75: "#2a5e59",
                      primary25: "#2a5e595e",
                      primary50: "#2a5e595e",
                      primary: "#2a5e59",
                    },
                  })}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      // "&:hover": { borderColor: "#00AB67" }, // border style on hover
                      border: "1px solid transparent", // default border color
                      boxShadow: "none", // no box-shadow
                      borderRadius: "20px",
                      marginRight: "0.5em",
                      background: "#f5f6fa",
                    }),
                  }}
                  onChange={(e) => {
                    setSelectedCustomer(e);
                    onCustomerSelect(e);
                    setCustomerErrorMessage("");
                  }}
                />
                <div className="error__message">{customerErrorMessage}</div>
              </>
            )}

            {issearch ? (
              <div
                className="book_app_customer_search_container"
                id="book_app_customer_search_container"
              >
                <div
                  style={{
                    fontSize: "18px",
                    marginTop: "2em",
                    fontWeight: "600",
                  }}
                >
                  Verify User
                </div>
                {/* <img
                  src={CloseIcon}
                  alt="close"
                  className="book_app_customer_search_close"
                  onClick={(event) => {
                    setissearch(false);
                  }}
                /> */}

                <div className="book_app_customer_search_input_container">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    className="book_app_customer_search_input"
                    maxLength={4}
                    onChange={(e) => {
                      setCode(e.currentTarget.value);
                      setCodeErrorMessage("");
                    }}
                  />
                  <div className="error__message">{codeErrorMessage}</div>
                  {/* <img src={SearchIconWhite} alt="search icon" /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button
                    className="inv_coupon_btn"
                    onClick={() => verifyOtp()}
                    style={{
                      width: "50%",
                      padding: "0.5em 0",
                      marginTop: "2em",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Verify"}
                  </button>
                </div>

                {/* {data.map((data) => (
                  <BookAppCustomerSearchResults
                    data={data}
                    setissearch={setissearch}
                  />
                ))} */}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="book_app_service_container">
            <div className="book_app_service_heading">Select service and time</div>

            {step === "1" ? (
              <div className="book_app_service_steps_container">
                <img
                  src={Step1Seleced}
                  alt="step1"
                  onClick={() => {
                    setstep("1");
                  }}
                />
                <img
                  src={Step2UnSeleced}
                  alt="step2"
                  onClick={() => {
                    if (isStep1Valid()) setstep("2");
                  }}
                />
                <img
                  src={Step3UnSeleced}
                  alt="step3"
                  onClick={() => {
                    if (isTimeValid()) setstep("3");
                  }}
                />
                <hr className="book_app_service_steps_line" />
              </div>
            ) : (
              ""
            )}

            {step === "2" ? (
              <div className="book_app_service_steps_container">
                <img
                  src={Step1UnSeleced}
                  alt="step1"
                  onClick={() => {
                    setstep("1");
                  }}
                />
                <img
                  src={Step2Seleced}
                  alt="step2"
                  onClick={() => {
                    if (isStep1Valid()) setstep("2");
                  }}
                />
                <img
                  src={Step3UnSeleced}
                  alt="step3"
                  onClick={() => {
                    if (isTimeValid()) setstep("3");
                  }}
                />
                <hr className="book_app_service_steps_line" />
              </div>
            ) : (
              ""
            )}

            {step === "3" ? (
              <div className="book_app_service_steps_container">
                <img
                  src={Step1UnSeleced}
                  alt="step1"
                  onClick={() => {
                    setstep("1");
                  }}
                />
                <img
                  src={Step2UnSeleced}
                  alt="step2"
                  onClick={() => {
                    if (isStep1Valid()) setstep("2");
                  }}
                />
                <img
                  src={Step3Seleced}
                  alt="step3"
                  onClick={() => {
                    if (isTimeValid()) setstep("3");
                  }}
                />
                <hr className="book_app_service_steps_line" />
              </div>
            ) : (
              ""
            )}

            {step === "1" ? (
              <>
                <div className="book_app_services_step_content">
                  <Select
                    placeholder="Service"
                    options={services}
                    value={selectedService}
                    className="book_app_customer_input"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary75: "#2a5e59",
                        primary25: "#2a5e595e",
                        primary50: "#2a5e595e",
                        primary: "#2a5e59",
                      },
                    })}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        // "&:hover": { borderColor: "#00AB67" }, // border style on hover
                        border: "1px solid transparent", // default border color
                        boxShadow: "none", // no box-shadow
                        borderRadius: "20px",
                        marginRight: "0.5em",
                        background: "#f5f6fa",
                      }),
                    }}
                    onChange={(e) => {
                      onServiceSelect(e);
                    }}
                  />
                </div>
                <div className="error__message">{serviceErrorMessage}</div>
              </>
            ) : (
              ""
            )}

            {step === "2" ? (
              <div className="book_app_services_step_content_2">
                <div className="calender_container">
                  <BookAppCalender setSelctedDate={setSelctedDate} selectedDate={selectedDate} />
                </div>

                <img src={BookAppServiceLine} alt="Line" className="book_app_service_line_style" />

                <div className="book_app_service_time_inputs">
                  {updatedSlots
                    .filter((element) => {
                      let time = `${element} - ${addHours(element)}`;
                      let timeSlotWithDate = selectedDate + "T" + element;
                      let dateTimeFindItem = cartArray.find((item) => {
                        let splittedStartTime = item.start_datetime.split("T");
                        let splittedEndTime = item.end_datetime.split("T");
                        let newTime = splittedStartTime[1] + " - " + splittedEndTime[1];
                        // let date = item.startDateTime.slice(
                        //   0,
                        //   item.startDateTime.length - 8
                        // );
                        return time == newTime;
                        // return (
                        //   moment(date).format("YYYY-MM-DD HH:mm") ==
                        //   moment(timeSlotWithDate).format("YYYY-MM-DD HH:mm")
                        // );
                      });
                      if (dateTimeFindItem == undefined) return element;
                    })
                    .map((item) => (
                      <button
                        className={
                          selectedTime == `${item} - ${addHours(item)}`
                            ? "Appointment_Date_Time_Wrapper_Selected"
                            : "Appointment_Date_Time_Wrapper"
                        }
                        onClick={() => {
                          setSelectedTime((selectedTime = `${item} - ${addHours(item)}`));
                          setStartTime((startTime = item));
                          // localStorage.setItem("slotsWithDate");
                          setSelectedStartTime((selectedStartTime = selectedDate + "T" + item));
                          setSelectedEndTime(
                            (selectedEndTime = selectedDate + "T" + addHours(item))
                          );
                        }}
                        style={addHours(item) > closingTime ? { display: "none" } : null}
                      >
                        {`${item} -  ${addHours(item)}`}
                      </button>
                    ))}
                  {/* <input
                    type="time"
                    value={startTime}
                    className={
                      startTime == ""
                        ? "book_app_service_time_inputs_start_style"
                        : "book_app_service_time_inputs_start_style__value"
                    }
                    onChange={(e) => {
                      setStartTime(e.currentTarget.value);
                      setStartTimeErrorMessage("");
                    }}
                  />
                  <div className="error__message">
                    {startTimeErrorMessage ? startTimeErrorMessage : ""}{" "}
                  </div>
                  <input
                    type="time"
                    value={endTime}
                    className={
                      endTime == ""
                        ? "book_app_service_time_inputs_end_style"
                        : "book_app_service_time_inputs_end_style__value"
                    }
                    onChange={(e) => {
                      setEndTime(e.currentTarget.value);
                      setEndTimeErrorMessage("");
                    }}
                  />
                  <div className="error__message">
                    {endTimeErrorMessage ? endTimeErrorMessage : ""}{" "}
                  </div> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {step === "3" ? (
              <>
                <div className="book_app_services_step_content">
                  <Select
                    placeholder="Employee"
                    options={isEmployeeLoading ? [{ value: 0, label: "Loading..." }] : employees}
                    value={selectedEmployee}
                    className="book_app_customer_input"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary75: "#2a5e59",
                        primary25: "#2a5e595e",
                        primary50: "#2a5e595e",
                        primary: "#2a5e59",
                      },
                    })}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        // "&:hover": { borderColor: "#00AB67" }, // border style on hover
                        border: "1px solid transparent", // default border color
                        boxShadow: "none", // no box-shadow
                        borderRadius: "20px",
                        marginRight: "0.5em",
                        background: "#f5f6fa",
                      }),
                    }}
                    onChange={(e) => {
                      setSelectedEmployee(e);
                      setEmployeeErrorMessage("");
                    }}
                  />
                </div>
                <div className="error__message">{employeeErrorMessage}</div>
              </>
            ) : (
              ""
            )}

            {step === "3" ? (
              <div className="book_app_service_buttons">
                {/* <button
                  className="book_app_services_next_btn"
                  onClick={(event) => {
                    // setstep("1");
                    // navigate("/book_appointment");
                    onCompleteButtonClick();
                  }}
                >
                  Complete
                </button> */}
                <button
                  className="book_app_services_addmore_btn"
                  onClick={(event) => {
                    // setstep("1");
                    // navigate("/book_appointment");
                    onAddMoreClick();
                  }}
                >
                  Add More
                </button>
              </div>
            ) : (
              <div className="book_app_service_buttons">
                <button
                  className="book_app_services_next_btn"
                  onClick={() => {
                    onNextClick();
                  }}
                >
                  Next
                </button>
              </div>
            )}

            {/* <img src={LeafImage2} alt="leaf" className="book_app_service_leaf" /> */}
          </div>

          <div className="book_app_booking_container">
            <table className="book_app_booking_table">
              <tbody>
                <tr className="book_app_booking_table_header">
                  <th className="book_app_booking_table_header_content">Service</th>
                  <th className="book_app_booking_table_header_content">Date</th>
                  <th className="book_app_booking_table_header_content">Time</th>
                  <th className="book_app_booking_table_header_content">Employee</th>
                  <th className="book_app_booking_table_header_content">Charges</th>
                </tr>
              </tbody>

              <tbody>
                {cartArray.length > 0 &&
                  cartArray.map((data, index) => (
                    <BookAppBookingsRow
                      data={data}
                      onDeleteClick={onDeleteClick}
                      onAddClick={onAdd}
                      index={index}
                      isLoading={isLoading}
                    />
                  ))}
              </tbody>
            </table>
          </div>

          {cartArray.length > 0 ? (
            <button
              className="book_app_checkout_btn"
              onClick={(event) => {
                window.scrollTo(0, 0);
                // window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                navigate("/billingsummary", {
                  state: { servicesData: cartArray },
                });
              }}
            >
              Checkout
            </button>
          ) : (
            <div style={{ marginBottom: "4em" }}></div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default BookAppointment;

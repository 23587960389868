import "./ModifyBooking.css";
import LeafImage from "../../img/leaf.png";
import ModifyBookingCard from "./ModifyBookingCard/ModifyBookingCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { addNewBooking, getAllAvailableServices } from "../../api/apicalls";

const ModifyBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const data = location.state ? location.state.datatosend : "";
  const [servicesArray, setServicesArray] = useState(
    data && data.booking_details ? data.booking_details : []
  );
  const branch_id = localStorage.getItem("branchId");
  const user_id = localStorage.getItem("userId");
  let vatPercentage = 0.15;
  const [isServiceUpdated, setIsServiceUpdated] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAllServices();
  }, []);

  const handleValuesChange = (e, index, msg, date, endDate) => {
    if (msg) var { name, value } = e;
    else var { name, value } = e.currentTarget;
    const list = [...servicesArray];

    if (msg == "time") {
      let startTime = value.split("-");
      var splittedDate = date.split("T");
      let newDate = moment(new Date(date)).format("YYYY-MM-DD");
      list[index]["end_datetime"] =
        splittedDate[0] + "T" + startTime[1].replace(/ /g, "") + ":00.000-04:00";
      list[index][name] = splittedDate[0] + "T" + startTime[0].replace(/ /g, "") + ":00.000-04:00";
    } else if (!msg && date && endDate) {
      let startTime = date.split("T");
      let endTime = endDate.split("T");
      list[index]["end_datetime"] = value + "T" + endTime[1];
      list[index][name] = value + "T" + startTime[1];
    } else if (name == "employee_name") {
      list[index]["employee_id"] = value;
      list[index]["employee_user_id"] = value;
      list[index]["employee_name"] = e.label;
    } else {
      list[index][name] = value;
    }
    console.log("list", list);
    setIsServiceUpdated(!isServiceUpdated);
    setServicesArray(list);
  };

  const getAllServices = () => {
    getAllAvailableServices(branch_id, 0, 1000, 1)
      .then((res) => {
        if (res.data.data.getAllServices.error_code === 0) {
          setServices(res.data.data.getAllServices.result);
        } else setServices([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const updateBooking = () => {
    let bookingArr = [];
    var price = 0;

    for (let index = 0; index < servicesArray.length; index++) {
      const element = servicesArray[index];
      let service = services.find((srv) => srv.id == element.service_id);
      // var newStartTime = moment(element.start_datetime)
      //   .subtract(13, "hours")
      //   .format("YYYY-MM-DDTHH:mm");

      // var newEndTime = moment(element.end_datetime)
      //   .subtract(13, "hours")
      //   .format("YYYY-MM-DDTHH:mm");
      let newStartDate = element.start_datetime.split(":00.000-");
      let newEndDate = element.end_datetime.split(":00.000-");
      let obj = {
        id: element.id,
        booking_id: element.booking_id,
        employee_user_id: parseInt(element.employee_user_id),
        service_id: parseInt(element.service_id),
        customer_user_id: parseInt(data.user_id),
        price: service.price,
        is_executive: 0,
        start_datetime: newStartDate[0] + ":00+00:00",
        end_datetime: newEndDate[0] + ":00+00:00",
        status_id: data.status_id,
        intervalTime: data.intervalTime,
      };
      bookingArr.push(obj);
    }

    for (let index = 0; index < bookingArr.length; index++) {
      const element = bookingArr[index];
      price = price + element.price;
    }
    let netPrice = price * vatPercentage;
    let requestObj = {
      bookingModel: {
        id: data.id,
        user_id: parseInt(data.user_id),
        status_id: data.status_id,
        price: price,
        vat: data.vat,
        discount: data.discount,
        discount_code: data.discount_code,
        net_price: netPrice,
        payment_type_id: data.payment_type_id,
        payment_status_id: data.payment_status_id,
        booking_type_id: data.booking_type_id,
        branch_id: parseInt(branch_id),
        booking_details: bookingArr,
        lang: 1,
      },
    };
    console.log("requestObj", requestObj);
    addNewBooking(requestObj)
      .then(({ data }) => {
        if (data.data.postBooking.error_code == 0) navigate("/receptionist_dashboard");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onSaveClick = () => {
    if (servicesArray[0].booking_id) updateBooking();
    else {
      const cartProducts = localStorage.getItem("cartProducts");
      let productsArray = JSON.parse(cartProducts);

      for (let index = 0; index < productsArray.length; index++) {
        const element = productsArray[index];
        if (element.service_id == servicesArray[0].service_id) {
          element.id = servicesArray[0].id;
          element.booking_id = servicesArray[0].booking_id;
          element.employee_user_id = parseInt(servicesArray[0].employee_user_id);
          element.employee_name = servicesArray[0].employee_name;
          element.service_id = parseInt(servicesArray[0].service_id);
          element.service_name = servicesArray[0].service_name;
          element.customer_user_id = parseInt(servicesArray[0].customer_user_id);
          element.price = servicesArray[0].price;
          element.is_executive = 0;
          element.start_datetime = servicesArray[0].start_datetime;
          element.end_datetime = servicesArray[0].end_datetime;
          element.status_id = 3;
        }
      }
      localStorage.setItem("cartProducts", JSON.stringify(productsArray));
      navigate("/book_appointment");
    }
  };

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Schedule</p>
      </div>

      {/* <div>
        <img src={LeafImage} alt="" className="leaf leaf_pos" />
      </div> */}

      <div className="modify_booking_container">
        <div className="modify_booking_heading">Modify Booking</div>

        <div className="modify_cards_container">
          {servicesArray.map((data, index) => (
            <ModifyBookingCard
              data={data}
              index={index}
              handleValuesChange={handleValuesChange}
              isServiceUpdated={isServiceUpdated}
            />
          ))}
        </div>

        <div className="modify_booking_save">
          <button
            className="modify_booking_save_btn"
            onClick={() => {
              onSaveClick();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ModifyBooking;

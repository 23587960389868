import "./ReceptionistDashboard.css";
import SearchIcon from "../../img/searchicon.png";
import { useEffect, useState } from "react";
import TableRow from "./TableRow/TableRow";
import ReceptionLeafImage from "../../img/receptionleaf.png";
import { GetBookingsApiCall, deleteBooking } from "../../api/apicalls";
import { search } from "../../img";
import { PuffLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CustomError } from "../Toast";

const ReceptionistDashboard = () => {
  const navigate = useNavigate();
  const pg = localStorage.getItem("pg");
  const pre = localStorage.getItem("pre");
  const [upcomingselected, setupcomingseleceted] = useState(pre ? false : true);
  const [previousselected, setpreviousseleceted] = useState(pre ? true : false);
  const [data_received, setdata_received] = useState(false);
  const [bookingType, setBookingType] = useState(1);

  const [dataList, setdataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let [page, setPage] = useState(pg ? parseInt(pg) : 1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [name, setName] = useState("");
  const [searchApiCall, setSearchApiCall] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const user = localStorage.getItem("receptionistId");

  useEffect(() => {
    if (!user) navigate("/");
  }, []);

  console.log("new Date()", new Date());
  console.log("new Date() format", moment(new Date()).format("YYYY-MM-DDTH:mm:ss"));

  useEffect(() => {
    getBookingList()
  }, [bookingType, page, searchApiCall]);

  const getBookingList = (msg) =>{
    setdata_received(false);
    let obj = {
      getbookings: {
        current_datetime:moment(new Date()).format("YYYY-MM-DDTH:mm:ss"),
        user_id: 0,
        branch_id: parseInt(localStorage.getItem("branchId")),
        limit: 10,
        page: page,
        booking_type: bookingType,
        search: name,
      },
    };
    if(!msg)
    setIsLoading(true);
    GetBookingsApiCall(obj)
      .then((res) => {
        setIsLoading(false);
        if (res.data.data.getBookings.error_code === 0) {
          setdata_received(true);
          setdataList(res.data.data.getBookings.result);
          setTotalRecords(res.data.data.getBookings.total_records);
        } else {
          setdataList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }

  const handlePageChange = (value) => {
    setPage((page = value));
    localStorage.setItem("pg", page);
  };

  const searchCall = () => {
    setSearchApiCall(!searchApiCall);
  };

  const onDelete = (item) => {
    let object = {
      ID: {
        id: item.id,
      },
    };
    setIsDeleteLoading(true);
    deleteBooking(object)
      .then(({ data }) => {
        setIsDeleteLoading(false);
        if (data.data.deleteBooking.error_code == 0)
        {
          // var filteredData = dataList.filter((x)=> x.id != item.id);
          // setdataList(filteredData)
          getBookingList()
        }
        else CustomError(data.data.deleteBooking.message);
      })
      .catch((err) => {
        setIsDeleteLoading(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="cover_image">
        <p className="reception_heading">Schedule</p>
      </div>

      <div className="rec_main_screen_wrapper">
        <div className="rec_main_screen">
          <div className="rec_searchbar">
            <input
              type="text"
              placeholder="Search here....."
              onChange={(e) => {
                setPage(1);
                setTotalRecords(0);
                setName(e.currentTarget.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  localStorage.removeItem("pg");
                  searchCall();
                }
              }}
            />
            <img
              src={search}
              alt="searchicon"
              onClick={() => {
                localStorage.removeItem("pg");
                searchCall();
              }}
            />
          </div>

          <div className="appointment_section">
            <div className="appointment_buttons">
              <button
                className={
                  upcomingselected
                    ? "appointment_button_selected"
                    : "appointment_button_unselected"
                }
                // onClick={(event) => {
                //   localStorage.setItem("pre", false);
                //   localStorage.removeItem("pg");
                //   setTotalRecords(0);
                //   setPage(1);
                //   setupcomingseleceted(true);
                //   setpreviousseleceted(false);
                //   setBookingType(1);
                // }}
              >
                Upcoming Appointments
              </button>
              {/* <button
                className={
                  previousselected
                    ? "appointment_button_selected"
                    : "appointment_button_unselected"
                }
                onClick={(event) => {
                  localStorage.setItem("pre", true);
                  localStorage.removeItem("pg");
                  setTotalRecords(0);
                  setPage(1);
                  setpreviousseleceted(true);
                  setupcomingseleceted(false);
                  setBookingType(0);
                }}
              >
                Previous Appointments
              </button> */}
            </div>

            <div className="appointment_table">
              <table
                className="table_dimensions"
                // style={{
                //   borderSpacing: "0 10px",
                //   borderCollapse: "separate",
                // }}
              >
                <thead>
                  <tr className="table_heading_Tr">
                    <th className="table_heading">Date</th>
                    <th className="table_heading">Time</th>
                    <th className="table_heading">Customer</th>
                    <th className="table_heading">Status</th>
                    <th className="table_heading">Services</th>
                  </tr>
                </thead>

                <tbody>
                  {!isLoading && dataList.length > 0
                    ? dataList.map((item) => <TableRow data={item} onDelete={onDelete} isDeleteLoading={isDeleteLoading} />)
                    : null}
                </tbody>
              </table>
              {isLoading ? (
                <div className="loader__container">
                  <PuffLoader
                    loading={isLoading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : dataList == null || dataList.length <= 0 ? (
                <div className="no__record__container__wrapper">
                  <div className="loader__container">No Appointments</div>
                </div>
              ) : null}
              {dataList.length > 0 ? (
                <div className="records__container__pagination">
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={10}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="reception_leaf">
        <img src={ReceptionLeafImage} alt="LeafImage" />
      </div> */}
    </>
  );
};

export default ReceptionistDashboard;

import "./InvoicePayment.css";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import { changeBookingStatus } from "../../../api/apicalls";
import { useNavigate } from "react-router-dom";

const disableScrollOnOpen = () => {
  document.body.style.overflow = "hidden";
};
const disableScrollOnClose = () => {
  document.body.style.overflow = "auto";
};

const InvoicePayment = ({
  paymentpopup,
  setpaymentpopup,
  netAmount,
  bookingId,
  isOnBillingSummary,
  onPayClick,
  isBookingLoading,
}) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(netAmount);
  const [paymentType, setPaymentType] = useState({ value: 10, label: "Cash" });
  const [isLoading, setIsLoading] = useState(
    isBookingLoading ? isBookingLoading : false
  );

  useEffect(() => {
    setAmount(netAmount);
  }, [netAmount]);

  const payment = () => {
    let requestObj = {
      paymentModel: {
        id: bookingId,
        payment_type_id: paymentType.value,
        payment_status_id: 13,
      },
    };
    setIsLoading(true);
    changeBookingStatus(requestObj)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.data.changeBookingStatus.error_code == 0) {
          navigate("/receptionist_dashboard");
          setpaymentpopup(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  const pay = () => {
    let paymentModel = {
      id: bookingId,
      payment_type_id: paymentType.value,
      payment_status_id: 13,
    };
    if (isOnBillingSummary) onPayClick(paymentModel);
    else payment();
  };

  return (
    <Modal
      className={"inv_payment_container"}
      overlayClassName="modal_Overlay"
      onAfterOpen={disableScrollOnOpen}
      onAfterClose={disableScrollOnClose}
      isOpen={paymentpopup}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        setpaymentpopup(false);
        console.log("paymentpopup", paymentpopup);
      }}
      shouldCloseOnEsc={true}
    >
      <div className="inv_payment_heading">Payment</div>

      <div className="inv_payment_paymentmethod_dropdown_wrapper">
        {/* <select
          name="payment_method"
          id="payment_method"
          className="inv_payment_paymentmethod_dropdown"
        >
          <option value="payment_method">Select Payment Method</option>
          <option value="online">Online</option>
          <option value="onsite">On-Site</option>
        </select> */}

        <Select
          placeholder="Time slots"
          options={[
            { value: 9, label: "Online" },
            { value: 10, label: "Cash" },
          ]}
          value={paymentType}
          name="start_datetime"
          onChange={(e) => setPaymentType(e)}
          className="book_app_customer_input__payment"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary75: "#2a5e59",
              primary25: "#2a5e595e",
              primary50: "#2a5e595e",
              primary: "#2a5e59",
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              "&:hover": {
                borderColor: "transparent",
                borderBottom: "1px solid #DFDFDF",
              }, // border style on hover
              border: "1px solid transparent", // default border color
              boxShadow: "none", // no box-shadow
              borderRadius: "30px",
              marginRight: "0.5em",
              background: "#EFEFEF",
              fontFamily: "Montserrat",
              height: "50px",
            }),
          }}
        />
      </div>

      <input
        type="text"
        placeholder="Enter amount"
        className="inv_payment_amount_input"
        value={amount}
        onChange={(e) => {
          setAmount(e.currentTarget.value);
        }}
      />

      <button className="inv_payment_pay_btn" onClick={() => pay()}>
        {isLoading ? "----" : "Pay"}
      </button>
    </Modal>
  );
};

export default InvoicePayment;

import "./EmployeeCard.css";

const EmployeeCard = ({ data }) => {
  return (
    <>
      <div className={"emp_card_free"}>
        <div
          className={data.is_free ? "emp_card_name_free" : "emp_card_name_busy"}
          // className={"emp_card_name_free"}
        >
          {data && data.name}
        </div>

        <div
          className={
            data.is_free ? "emp_card_status_free" : "emp_card_status_busy"
          }
        >
          {data.is_free ? "Free" : "Busy"}
        </div>
      </div>
    </>
  );
};

export default EmployeeCard;
